import React, { useState } from 'react';
import QrScanner from 'react-qr-scanner';
import { ethers, getAddress, verifyMessage  } from 'ethers';

const CONTRACT_ADDRESS = '0x3f55c6cEA3652337098fbD23C608A659bcdA0744';
const POLYGON_RPC = 'https://polygon-rpc.com'; // Official Polygon RPC URL

function ReadQR() {
  const [scanResult, setScanResult] = useState(null);
  const [isNFTHolder, setIsNFTHolder] = useState(null);
  const [loading, setLoading] = useState(false);

  // Function to handle QR code scan
  const handleScan = (data) => {
    if (data) {
      const qrData = data.text.trim();
      // Address: 0xf2a4d37a5648fd5ad3c72e4c92aad26786185d57 Date: 2024-11-08T08:59:22.332Z Signature: 0x82f897f328f68ab64416a72b57dc5cb50ad448f6e79a8278cdead7107fd302391a9bb4b59b986560a2b4fb30923bb90bdce160683a214ac3f5b890032b5496991b
      const addressMatch = qrData.match(/Address:\s*(0x[a-fA-F0-9]{40})/);
      const dateMatch = qrData.match(/Date:\s*(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z)/);
      const signatureMatch = qrData.match(/Signature:\s*(0x[a-fA-F0-9]+)/);
      if (!addressMatch) {
        alert('Address not found');
        return;
      } else if (!dateMatch) {
        alert('Date/Time not found');
        return;
      } else if (!signatureMatch) {
        alert('Signature not found');
        return;
      } 

      let walletAddress;
      try {
          // Validate and checksum the Ethereum address
          walletAddress = getAddress(addressMatch[1]);
      } catch (error) {
          console.error("Invalid Ethereum address:", error.message);
          return;
      }

      const dateTime = dateMatch[1];
      const signature = signatureMatch[1];
      console.log(`walletAddress --> ${walletAddress}`);
      setScanResult(walletAddress);
      checkNFTHolder(walletAddress);

      // Step 2: Verify the signature against the address
      const recoveredAddress = verifyMessage(dateTime, signature);

      console.log("Original Address:", walletAddress);
      console.log("Recovered Address:", recoveredAddress);

      if (recoveredAddress.toLowerCase() === walletAddress.toLowerCase()) {
        console.log("✅ Signature is valid!");
      } else {
        console.log("❌ Signature is invalid.");
      }
    }
  };

  const handleError = (error) => {
    console.error('Error scanning QR code:', error);
  };

  const previewStyle = {
    height: 240,
    width: 320,
  };

  // Function to check if the scanned wallet holds an NFT
  const checkNFTHolder = async (walletAddress) => {
    if (!ethers.isAddress(walletAddress)) {
      alert('Invalid Ethereum address');
      return;
    }

    setLoading(true);
    try {
      // Connect to the Polygon network
      const provider = new ethers.JsonRpcProvider(POLYGON_RPC);
      const contract = new ethers.Contract(
        CONTRACT_ADDRESS,
        ['function balanceOf(address owner) view returns (uint256)'],
        provider
      );

      // Check the balance of NFTs for the given wallet
      const balance = await contract.balanceOf(walletAddress);
      setIsNFTHolder(balance > 0);
    } catch (error) {
      console.error('Error checking NFT balance:', error);
      setIsNFTHolder(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <h2>Read QR Code & Check NFT Ownership</h2>
      <QrScanner
        delay={300}
        style={previewStyle}
        onError={handleError}
        onScan={handleScan}
      />

      {scanResult && (
        <div>
          <h3>Scanned Wallet Address:</h3>
          <p>{scanResult}</p>
        </div>
      )}

      {loading ? (
        <p>Checking NFT ownership...</p>
      ) : isNFTHolder !== null && (
        <div>
          <h3>NFT Ownership:</h3>
          <p>{isNFTHolder ? 'Wallet holds the NFT' : 'Wallet does not hold the NFT'}</p>
        </div>
      )}
    </div>
  );
}

export default ReadQR;
