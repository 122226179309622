import React, { useState } from 'react';
import QRCode from 'qrcode';
import { ethers } from 'ethers';

function GenerateQR() {
  const [account, setAccount] = useState(null);
  const [text, setText] = useState('');
  const [qrCode, setQrCode] = useState('');
  const [signature, setSignature] = useState('');

  // Function to connect to MetaMask
  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const accounts = await provider.send("eth_requestAccounts", []);
        setAccount(accounts[0]);
      } catch (error) {
        console.error('Error connecting to MetaMask:', error);
      }
    } else {
      alert('MetaMask is not installed!');
    }
  };

  // Function to sign the current datetime
  const signMessage = async () => {
    if (account) {
      try {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();

        // Get the current datetime
        const currentDateTime = new Date().toISOString();
        setText(currentDateTime);

        // Sign the current datetime
        const signedMessage = await signer.signMessage(currentDateTime);
        setSignature(signedMessage);

        // Generate a QR code with the signed message
        const qrCodeData = await QRCode.toDataURL(
          `Address: ${account}\nDate: ${currentDateTime}\nSignature: ${signedMessage}`
        );
        setQrCode(qrCodeData);
      } catch (error) {
        console.error('Error signing message:', error);
      }
    } else {
      alert('Please connect your wallet first!');
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <h2>Generate QR Code with Wallet Signature</h2>
      
      {!account ? (
        <button onClick={connectWallet}>Connect MetaMask</button>
      ) : (
        <>
          <p>Connected: {account}</p>
          <button onClick={signMessage}>Sign Current DateTime</button>
        </>
      )}

      {signature && (
        <div>
          <h3>Signature:</h3>
          <p>{signature}</p>
        </div>
      )}

      {qrCode && (
        <div>
          <h3>Generated QR Code:</h3>
          <img src={qrCode} alt="QR Code" />
        </div>
      )}
    </div>
  );
}

export default GenerateQR;