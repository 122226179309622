import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import GenerateQR from './pages/GenerateQR';
import ReadQR from './pages/ReadQR';

function App() {
  return (
    <Router>
      <div style={{ padding: '20px' }}>
        <nav>
          <Link to="/">Generate QR</Link> | <Link to="/readqr">Read QR</Link>
        </nav>
        <Routes>
          <Route path="/" element={<GenerateQR />} />
          <Route path="/readqr" element={<ReadQR />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
